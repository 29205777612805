import type { RouteComponentProps } from '@gatsbyjs/reach-router'
import React, { FC, useEffect } from 'react'

import { useAuth } from '../../../lib/auth'
import {
  isProfessionalResourceTypeSlug,
  isPublicResourceTypeSlug,
  isValidAssetResourceTypeSlug,
  libraryUrl,
} from '../../../lib/library'
import { Asset } from './Asset'

export type AssetRouteProps = RouteComponentProps<{ type: string; slug: string }>

export const AssetRoute: FC<AssetRouteProps> = ({ type, slug }) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth()

  useEffect(() => {
    if (isLoading) {
      return
    }
    if (!type || !slug || !isValidAssetResourceTypeSlug(type)) {
      return
    }
    if (
      isAuthenticated ||
      isPublicResourceTypeSlug(type) ||
      !isProfessionalResourceTypeSlug(type)
    ) {
      return
    }
    ;(async (): Promise<void> => {
      await loginWithRedirect({
        appState: {
          returnTo: `${libraryUrl}/assets/${type}/${slug}`,
        },
      })
    })()
  }, [isAuthenticated, isLoading, loginWithRedirect, type, slug])

  if (isLoading || !type || !slug || !isValidAssetResourceTypeSlug(type)) {
    return null
  }

  return <Asset type={type} slug={slug} />
}
