import { GlobalStyle } from '@designsforhealth/dfh-react-components'
import { Router } from '@gatsbyjs/reach-router'
import React, { FC } from 'react'
import styled, { css } from 'styled-components'

import Head from '../../../layouts/Head'
import { getRouterBasePath, useDispensaryContext } from '../../../lib/dispensaries'
import { libraryUrl } from '../../../lib/library'
import Layout from '../../global/Layout'
import { AssetRootRoute } from './AssetRootRoute'
import { AssetRoute } from './AssetRoute'

const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.grayscale.white};
  `}
`

export const AssetsPage: FC = () => (
  <>
    <Head title="Asset" />
    <GlobalStyle includeTypography />
    <Layout>
      <Container>
        <Router basepath={getRouterBasePath(`${libraryUrl}/assets`, !!useDispensaryContext())}>
          <AssetRootRoute path="/" />
          <AssetRootRoute path="/:type" />
          <AssetRoute path="/:type/:slug" />
        </Router>
      </Container>
    </Layout>
  </>
)
