import axios from 'axios'
import { useEffect, useState } from 'react'

import { useAccessToken } from './useAccessToken'

export interface UseSessionAuth {
  called: boolean
  loading: boolean
  error: Error | null
}

export const useSessionAuth = (): UseSessionAuth => {
  const [{ token, loading: loadingToken }] = useAccessToken()
  const [called, setCalled] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    ;(async () => {
      if (loadingToken) {
        return
      }
      try {
        const headers: Record<string, string> = {}
        if (token) {
          headers.Authorization = `Bearer ${token}`
        }
        headers['cache-control'] = 'no-cache'
        headers['pragma'] = 'no-cache'
        setCalled(true)
        await axios.put('/api/session-auth', {}, { headers })
      } catch (err) {
        console.error('useSessionAuth error', err)
        setError(err instanceof Error ? err : new Error(`${err}`))
      } finally {
        setLoading(false)
      }
    })()
  }, [loadingToken, token])

  return { called, loading, error }
}
